import { useNavigate, useParams } from '@solidjs/router';
import { JSX, Show, createEffect, createSignal, onMount } from 'solid-js';
import 'tailwindcss/tailwind.css';
import {
  I_HAVE_READ_AND_AGREED_TO_THE_TERMS,
  NEXT,
  PREVIOUS,
  TRANS_UNION_TERMS,
  MAGICDOOR_TERMS,
  WELCOME_TO_MAGIC_DOOR,
  MAGIC_DOOR_PROVIDES_A_PLATFORM,
  RENT_COLLECTION,
  MAINTENANCE_MANAGEMENT,
  TENANT_COMMUNICATION,
  FINANCIAL_REPORTING,
  RENTAL_APPLICATION_CHARGES_NON_REFUNDABLE,
  VIEW_IN_FULL_SCREEN,
} from '~/assets/strings';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { Button } from '~/components/common/buttons';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationStep } from '~/types/RentalApplicationSteps';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';

export const TermsAndConditions = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useLocalization();

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);
  const { execute: updateRentalApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: goToNextStep, isLoading: isGoingToNextStep } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalApplication, didSucceed: didSucceedValidation } = useUseCase(ValidateRentalApplicationInfoUseCase);

  const TRANSUNION_PDF_URL = '/TransUnionTerms.pdf#view=FitH&toolbar=0&navpanes=0&scrollbar=0';
  const magicDoorTermsContent = (
    <>
      <p>{t(WELCOME_TO_MAGIC_DOOR)}</p>
      <br />
      <p>{t(MAGIC_DOOR_PROVIDES_A_PLATFORM)}</p>
      <ul>
        <li>- {t(RENT_COLLECTION)}</li>
        <li>- {t(MAINTENANCE_MANAGEMENT)}</li>
        <li>- {t(TENANT_COMMUNICATION)}</li>
        <li>- {t(FINANCIAL_REPORTING)}</li>
      </ul>
      <br />
      <p>{t(RENTAL_APPLICATION_CHARGES_NON_REFUNDABLE)}</p>
    </>
  );

  const onAcceptedTransUnionTerms = (hasAccepted: boolean) => {
    updateRentalApplication({ hasAcceptedTransUnionTerms: hasAccepted });
  };

  const onAcceptedMagicDoorTerms = (hasAccepted: boolean) => {
    updateRentalApplication({ hasAcceptedMagicDoorTerms: hasAccepted });
  };

  createEffect(() => {
    if (!didSucceedValidation() || presentableRentalApplication()?.errors?.hasLicenseAgreementErrors) {
      return;
    }
    goToNextStep({
      currentStep: RentalApplicationStep.TermsAndConditions,
      credentials: { id: params.id, password: params.password },
    });
  });

  onMount(() => {
    getRentalApplication({ id: params.id, password: params.password });
  });

  return (
    <Show
      when={!isLoadingApplication() && !isGoingToNextStep()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="scrollbar flex h-section3 flex-col overflow-y-auto bg-white p-5">
        <div class="grow">
          <TermsAndConditionSections
            title={t(MAGICDOOR_TERMS)}
            content={magicDoorTermsContent}
            accepted={presentableRentalApplication()?.hasAcceptedMagicDoorTerms}
            onAcceptChanged={onAcceptedMagicDoorTerms}
            error={presentableRentalApplication()?.errors?.magicDoorLicenseError}
          />
          <TermsAndConditionSections
            title={t(TRANS_UNION_TERMS)}
            iframe={TRANSUNION_PDF_URL}
            accepted={presentableRentalApplication()?.hasAcceptedTransUnionTerms}
            onAcceptChanged={onAcceptedTransUnionTerms}
            error={presentableRentalApplication()?.errors?.transUnionLicenseError}
          />
        </div>
      </div>
      <div class="fixed bottom-0 left-0 z-20 w-full border-t border-gray-200 bg-white px-4 py-3">
        <div class="flex justify-between">
          <Button type="button" onClick={() => navigate(`/rental-applications/${params.id}/${params.password}/rental-questions`)}>
            {t(PREVIOUS)}
          </Button>
          <Button type="button" onClick={validateRentalApplication}>
            {t(NEXT)}
          </Button>
        </div>
      </div>
    </Show>
  );
};

type TermsAndConditionsProps = {
  title: string;
  content?: string | JSX.Element;
  iframe?: string;
  accepted?: boolean;
  error?: string;
  onAcceptChanged: (hasAccepted: boolean) => void;
};

const TermsAndConditionSections = (props: TermsAndConditionsProps) => {
  const { t } = useLocalization();

  const [iframeRef, setIframeRef] = createSignal<HTMLIFrameElement>();

  const openInNewTab = () => {
    if (iframeRef()) {
      window.open(props.iframe, '_blank');
    }
  };

  return (
    <div class="h-fit overflow-hidden rounded-lg p-2.5">
      <div class="flex items-center justify-between">
        <h2 class="mb-2 text-lg font-semibold text-primary-color">{props.title}</h2>
        <Show when={props.iframe}>
          <button type="button" class="text-sm text-primary-color underline" onClick={openInNewTab}>
            {t(VIEW_IN_FULL_SCREEN)}
          </button>
        </Show>
      </div>
      <Show when={props.iframe}>
        <iframe ref={setIframeRef} src={props.iframe} class="h-modalSm w-full rounded-lg bg-input p-2" />
      </Show>
      <Show when={props.content}>
        <div class="scrollbar h-fit overflow-auto rounded-lg bg-input px-4 py-3 text-sm text-text-level01 ">{props.content}</div>
      </Show>
      <div class="mb-4">
        <div class="flex flex-col pt-3">
          <div class="flex items-center">
            <input
              type="checkbox"
              checked={props.accepted}
              onInput={() => props.onAcceptChanged(!props.accepted)}
              class="form-checkbox size-5 text-blue-600"
            />
            <div class="ml-2 text-sm">{t(I_HAVE_READ_AND_AGREED_TO_THE_TERMS)}</div>
          </div>
          <Show when={props.error}>
            <div class="text-s my-1 text-red-500">{props.error}</div>
          </Show>
        </div>
      </div>
    </div>
  );
};
